'use client';

import { useReportWebVitals } from 'next/web-vitals';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function WebVitals(): any {
  useReportWebVitals((metric) => {
    console.log(metric);
  });
}
